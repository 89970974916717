import React from "react";
import "./LocalOfficials.scss";
import LocalOfficialCard from "./LocalOfficialCard";

// images
import Header from "../images/header-1.png";
import mayor from "../images/officials/mayor.jpg";
import vice from "../images/officials/vice.jpg";
import debie from "../images/officials/tan.jpg";
import secuya from "../images/officials/secuya.jpg";
import glenn from "../images/officials/arangcon.jpg";
import dresel from "../images/officials/dresel.JPG";
import barrie from "../images/officials/barrie.jpg";
import chan from "../images/officials/chan.jpg";
import humol from "../images/officials/humol.jpg";
import mandin from "../images/officials/mandin.jpg";
import masambo from "../images/officials/masambo.jpg";
import sotto from "../images/officials/sotto.jpg";
import ancla from "../images/officials/no_image.jpg";
import Footer from "./Footer";

function LocalOfficials() {
  return (
    <div className="localOfficials">
      <div className="img_container">
        <img src={Header} alt="" />
      </div>
      <div className="localOfficials__container">
        <div className="localOfficials__mayor_container">
          <LocalOfficialCard
            image={mayor}
            title="Municipal  Mayor"
            name="Hon. Myrocel C. Balili"
          />
        </div>
        <hr />
        <div className="localOfficials__vice_container">
          <LocalOfficialCard
            image={vice}
            title="Municipal Vice Mayor"
            name="Hon. Rodel G. Balili"
          />
        </div>
      </div>
      <div className="localOfficials__sb_container">
        <h1 style={{ textAlign: "center" }} className="localOfficials__h1">
          Sangguniang Bayan Members
        </h1>
        <div className="localOfficials__sb_container_cards_container">
          <LocalOfficialCard
            image={ancla}
            title="Hon. Atty. Carlo D. Ancla"
            name=""
          />
          <LocalOfficialCard
            image={humol}
            title="Hon. Ivi Sarah M. Humol"
            name=""
          />
          <LocalOfficialCard
            image={glenn}
            title="Hon. Glenn F. Arangcon "
            name=""
          />
        </div>
        <div className="localOfficials__sb_container_cards_container">
          <LocalOfficialCard
            image={barrie}
            title="Hon. Barrie V. Mencidor, J.D"
            name=""
          />
          <LocalOfficialCard
            image={debie}
            title="Hon. Debie G. Tan, MMPA"
            name=""
          />
          <LocalOfficialCard
            image={mandin}
            title="Hon. Jeovy G. Mandin, MMPA"
            name=""
          />
        </div>
        <div className="localOfficials__sb_container_cards_container">
          <LocalOfficialCard
            image={secuya}
            title="Hon. Francis P. Secuya, MMPA"
            name=""
          />
          <LocalOfficialCard
            image={sotto}
            title="Hon. Mila Cezarina M. Sotto"
            name=""
          />
          <LocalOfficialCard
            image={masambo}
            title="Hon. Felipe B. Masambo "
            name=""
          />
        </div>
        <div className="localOfficials__sb_container_cards_container">
          <LocalOfficialCard
            image={chan}
            title="Hon. Mark Anthony O. Chan"
            name=""
          />

          <LocalOfficialCard
            image={dresel}
            title="Hon. Dresel Refamonte"
            name=""
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LocalOfficials;
